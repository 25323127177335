var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    _vm._b(
      {
        class: "sale-items-table font-weight-bold mb-0 " + _vm.$attrs.class,
        style: { minHeight: _vm.minHeight + "px" },
        attrs: { "show-empty": "" },
      },
      "b-table-simple",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "b-thead",
        { staticClass: "sale-items-thead" },
        [
          _c(
            "b-tr",
            [
              _vm._l(_vm.fields, function (field) {
                return [
                  _c(
                    "b-th",
                    {
                      key: field.key,
                      staticClass: "sale-items-th",
                      class: field.class,
                      style: field.thStyle,
                    },
                    [_vm._v(" " + _vm._s(field.label) + " ")]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-tbody",
        { staticClass: "sale-items-tbody" },
        [
          _vm.isItemsEmpty
            ? _c(
                "b-tr",
                [
                  _c(
                    "b-td",
                    {
                      staticClass: "text-center my-auto",
                      attrs: { colspan: "6" },
                    },
                    [
                      _c("div", [
                        _c("p", { staticClass: "h4" }, [
                          _vm._v(" Nenhum Produto "),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._l(_vm.tableItems, function (item, index) {
                return [
                  _c(
                    "b-tr",
                    { key: index, staticClass: "row-item" },
                    [
                      _vm.showNumber
                        ? _c("b-td", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.product.kit
                                    ? ""
                                    : _vm.getPositionItem(index)
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "b-td",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _vm.showImg
                            ? _c("b-img", {
                                staticClass: "rounded mr-2",
                                staticStyle: { "object-fit": "contain" },
                                attrs: {
                                  src: item.product.imageSquare || _vm.logoImg,
                                  width: "50",
                                  height: "50",
                                  alt: "imagem",
                                },
                              })
                            : _vm._e(),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(" " + _vm._s(item.product.name) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-td",
                        {
                          staticClass: "text-center edit-cell",
                          attrs: { title: _vm.$t("Duplo clique para alterar") },
                          on: {
                            dblclick: function ($event) {
                              return _vm.showEditAmountCell(index, item)
                            },
                          },
                        },
                        [
                          !_vm.itemReadOnly && index === _vm.indexUpdate
                            ? _c(
                                "FormulateForm",
                                {
                                  on: {
                                    submit: function ($event) {
                                      return _vm.updateItem(item)
                                    },
                                  },
                                },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "paybox-item-list-amount-" + index,
                                      name: "QTDE.",
                                      type: "number",
                                      validation: "required|min:1",
                                    },
                                    model: {
                                      value: _vm.formUpdate.amount,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formUpdate, "amount", $$v)
                                      },
                                      expression: "formUpdate.amount",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(" " + _vm._s(item.amount) + " "),
                              ]),
                        ],
                        1
                      ),
                      _c(
                        "b-td",
                        { class: _vm.priceTextAlign },
                        [
                          item.discount > 0 ||
                          item.product.unitDiscount > 0 ||
                          item.discountPriceTable > 0
                            ? [
                                _c(
                                  "span",
                                  { staticClass: "sale-items-canceled-price" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.unitValue ||
                                              item.product.price,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.priceInfo.priceWithDiscount,
                                          null,
                                          2
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            : item.promotionId &&
                              (item.product.priceFrom > 0 ||
                                item.product.originalPrice > 0)
                            ? [
                                _c(
                                  "span",
                                  { staticClass: "sale-items-canceled-price" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.product.priceFrom ||
                                              item.product.originalPrice,
                                            null,
                                            2
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.priceInfo.priceWithDiscount,
                                          null,
                                          2
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            : [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.unitValue || item.product.price,
                                          null,
                                          2
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                        ],
                        2
                      ),
                      _c("b-td", { class: _vm.priceTextAlign }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(item.subtotal, null, 2)) +
                            " "
                        ),
                      ]),
                      !_vm.itemReadOnly
                        ? _c("b-td", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-center",
                                staticStyle: { gap: "10px" },
                              },
                              [
                                index !== _vm.indexUpdate
                                  ? [
                                      _c("e-button", {
                                        attrs: {
                                          id:
                                            "paybox-item-list-delete-" + index,
                                          tabindex: "-1",
                                          size: "sm",
                                          variant: "danger",
                                          icon: "trash",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItem(index, item)
                                          },
                                        },
                                      }),
                                    ]
                                  : [
                                      _c("e-button", {
                                        attrs: {
                                          id:
                                            "paybox-item-list-amount-confirm-update-" +
                                            index,
                                          title: "Confirmar",
                                          size: "sm",
                                          variant: "primary",
                                          icon: "check-circle",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateItem(item)
                                          },
                                        },
                                      }),
                                      _c("e-button", {
                                        attrs: {
                                          id:
                                            "paybox-item-list-amount-cancel-update-" +
                                            index,
                                          title: "Cancelar",
                                          size: "sm",
                                          variant: "danger",
                                          icon: "x-circle",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cleanUpdate(item)
                                          },
                                        },
                                      }),
                                    ],
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  item.product.kit
                    ? _vm._l(
                        _vm.getKitItems(item.product.kitItems),
                        function (kitItem, kitIndex) {
                          return _c(
                            "b-tr",
                            {
                              key: index + "_" + kitIndex,
                              staticClass: "row-item",
                            },
                            [
                              _vm.showNumber
                                ? _c("b-td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getPositionItem(index) +
                                            (_vm.getKitItems(
                                              item.product.kitItems
                                            ).length -
                                              1) -
                                            kitIndex
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "b-td",
                                { staticStyle: { "padding-left": "30px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "mr-1 mb-0" },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "arrow-return-right",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.showImg
                                        ? _c("b-img", {
                                            staticClass: "rounded mr-2",
                                            attrs: {
                                              src:
                                                kitItem.product.imageSquare ||
                                                _vm.logoImg,
                                              width: "50",
                                              height: "50",
                                              alt: "imagem",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("p", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(kitItem.product.name) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("b-td", { staticClass: "text-center" }, [
                                _vm._v(" " + _vm._s(kitItem.amount) + " "),
                              ]),
                              _c(
                                "b-td",
                                { class: _vm.priceTextAlign },
                                [
                                  kitItem.discount > 0 ||
                                  kitItem.product.unitDiscount > 0
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "sale-items-canceled-price",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    kitItem.unitValue ||
                                                      kitItem.product.price,
                                                    null,
                                                    2
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  kitItem.priceInfo
                                                    .priceWithDiscount,
                                                  null,
                                                  2
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    : [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  kitItem.unitValue ||
                                                    kitItem.product.price,
                                                  null,
                                                  2
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                ],
                                2
                              ),
                              _c("b-td"),
                              !_vm.itemReadOnly ? _c("b-td") : _vm._e(),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  item.product.lendingItemAssociated
                    ? _vm._l(
                        item.product.lendingItemAssociated,
                        function (lendingItem, lendingIndex) {
                          return _c(
                            "b-tr",
                            {
                              key:
                                index +
                                "_" +
                                lendingIndex +
                                "_" +
                                lendingItem.id,
                              staticClass: "row-item",
                            },
                            [
                              _vm.showNumber ? _c("b-td") : _vm._e(),
                              _c(
                                "b-td",
                                { staticStyle: { "padding-left": "30px" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(lendingItem.product.name) + " "
                                  ),
                                ]
                              ),
                              _c("b-td", { staticClass: "text-center" }, [
                                _vm._v(" " + _vm._s(lendingItem.amount) + " "),
                              ]),
                              _c("b-td", { class: _vm.priceTextAlign }, [
                                _vm._v(
                                  " " + _vm._s(_vm._f("currency")(0)) + " "
                                ),
                              ]),
                              _c("b-td"),
                              !_vm.itemReadOnly ? _c("b-td") : _vm._e(),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ]
              }),
          !_vm.isItemsEmpty && _vm.showTableFooter
            ? [
                _c(
                  "b-tr",
                  { staticClass: "row-footer text-light" },
                  [
                    _vm.showNumber ? _c("b-td") : _vm._e(),
                    _c("b-td", { staticClass: "text-right" }, [
                      _vm._v(" Total: "),
                    ]),
                    _c("b-td", { staticClass: "text-center" }, [
                      _vm._v(
                        " " + _vm._s(_vm.resumeData.totalQuantity || 0) + " "
                      ),
                    ]),
                    _c("b-td", { staticClass: "text-right" }, [
                      _vm._v(" Total: "),
                    ]),
                    _c("b-td", { class: _vm.priceTextAlign }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("currency")(_vm.resumeData.total)) +
                          " "
                      ),
                    ]),
                    !_vm.itemReadOnly ? _c("b-td") : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }