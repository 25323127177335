<template>
  <b-table-simple
    v-bind="$attrs"
    show-empty
    :class="`sale-items-table font-weight-bold mb-0 ${$attrs.class}`"
    :style="{ minHeight: `${minHeight}px` }"
  >
    <b-thead class="sale-items-thead">
      <b-tr>
        <template v-for="field in fields">
          <b-th
            :key="field.key"
            class="sale-items-th"
            :class="field.class"
            :style="field.thStyle"
          >
            {{ field.label }}
          </b-th>
        </template>
      </b-tr>
    </b-thead>

    <b-tbody class="sale-items-tbody">
      <b-tr v-if="isItemsEmpty">
        <b-td
          colspan="6"
          class="text-center my-auto"
        >
          <div>
            <p class="h4">
              Nenhum Produto
            </p>
          </div>
        </b-td>
      </b-tr>

      <template
        v-for="(item, index) in tableItems"
        v-else
      >
        <!-- row com o item principal -->
        <b-tr
          :key="index"
          class="row-item"
        >
          <b-td
            v-if="showNumber"
            class="text-center"
          >
            {{ item.product.kit ? '' : getPositionItem(index) }}
          </b-td>
          <b-td class="d-flex align-items-center">
            <b-img
              v-if="showImg"
              :src="item.product.imageSquare || logoImg"
              width="50"
              height="50"
              alt="imagem"
              class="rounded mr-2"
              style="object-fit: contain;"
            />
            <p class="mb-0">
              {{ item.product.name }}
            </p>
          </b-td>
          <b-td
            class="text-center edit-cell"
            :title="$t('Duplo clique para alterar')"
            @dblclick="showEditAmountCell(index, item)"
          >
            <FormulateForm
              v-if="!itemReadOnly && index === indexUpdate"
              @submit="updateItem(item)"
            >
              <FormulateInput
                :id="`paybox-item-list-amount-${index}`"
                v-model="formUpdate.amount"
                name="QTDE."
                type="number"
                validation="required|min:1"
              />
            </FormulateForm>
            <span v-else> {{ item.amount }} </span>
          </b-td>

          <b-td :class="priceTextAlign">
            <template
              v-if="
                item.discount > 0 || item.product.unitDiscount > 0 || item.discountPriceTable > 0
              "
            >
              <span class="sale-items-canceled-price">
                {{ (item.unitValue || item.product.price) | currency(null, 2) }}
              </span>
              <br>
              <span> {{ item.priceInfo.priceWithDiscount | currency(null, 2) }} </span>
            </template>

            <template
              v-else-if="
                item.promotionId && (item.product.priceFrom > 0 || item.product.originalPrice > 0)
              "
            >
              <!-- TODO pegar o priceFrom qnd promoção já tiver acabado porém no pedido ainda possuir o id da promoção -->
              <span class="sale-items-canceled-price">
                {{ item.product.priceFrom || item.product.originalPrice | currency(null, 2) }}
              </span>
              <br>
              <span> {{ item.priceInfo.priceWithDiscount | currency(null, 2) }} </span>
            </template>

            <template v-else>
              <span> {{ (item.unitValue || item.product.price) | currency(null, 2) }} </span>
            </template>
          </b-td>

          <b-td :class="priceTextAlign">
            {{ item.subtotal | currency(null, 2) }}
          </b-td>

          <b-td v-if="!itemReadOnly">
            <div
              class="d-flex align-items-center justify-content-center"
              style="gap: 10px"
            >
              <template v-if="index !== indexUpdate">
                <e-button
                  :id="`paybox-item-list-delete-${index}`"
                  tabindex="-1"
                  size="sm"
                  variant="danger"
                  icon="trash"
                  @click="removeItem(index, item)"
                />
              </template>
              <template v-else>
                <e-button
                  :id="`paybox-item-list-amount-confirm-update-${index}`"
                  title="Confirmar"
                  size="sm"
                  variant="primary"
                  icon="check-circle"
                  @click="updateItem(item)"
                />

                <e-button
                  :id="`paybox-item-list-amount-cancel-update-${index}`"
                  title="Cancelar"
                  size="sm"
                  variant="danger"
                  icon="x-circle"
                  @click="cleanUpdate(item)"
                />
              </template>
            </div>
          </b-td>
        </b-tr>

        <!-- row com item do KIT -->
        <template v-if="item.product.kit">
          <b-tr
            v-for="(kitItem, kitIndex) in getKitItems(item.product.kitItems)"
            :key="`${index}_${kitIndex}`"
            class="row-item"
          >
            <b-td
              v-if="showNumber"
              class="text-center"
            >
              {{
                getPositionItem(index) + (getKitItems(item.product.kitItems).length - 1) - kitIndex
              }}
            </b-td>
            <b-td style="padding-left: 30px">
              <div class="d-flex align-items-center">
                <p class="mr-1 mb-0">
                  <b-icon icon="arrow-return-right" />
                </p>
                <b-img
                  v-if="showImg"
                  :src="kitItem.product.imageSquare || logoImg"
                  width="50"
                  height="50"
                  alt="imagem"
                  class="rounded mr-2"
                />
                <p class="mb-0">
                  {{ kitItem.product.name }}
                </p>
              </div>
            </b-td>
            <b-td class="text-center">
              {{ kitItem.amount }}
            </b-td>
            <b-td :class="priceTextAlign">
              <template v-if="kitItem.discount > 0 || kitItem.product.unitDiscount > 0">
                <span class="sale-items-canceled-price">
                  {{ (kitItem.unitValue || kitItem.product.price) | currency(null, 2) }}
                </span>
                <br>
                <span> {{ kitItem.priceInfo.priceWithDiscount | currency(null, 2) }} </span>
              </template>
              <template v-else>
                <span>
                  {{ (kitItem.unitValue || kitItem.product.price) | currency(null, 2) }}
                </span>
              </template>
            </b-td>
            <b-td />
            <b-td v-if="!itemReadOnly" />
          </b-tr>
        </template>

        <!-- row com item associado (item comodato) -->
        <template v-if="item.product.lendingItemAssociated">
          <b-tr
            v-for="(lendingItem, lendingIndex) in item.product.lendingItemAssociated"
            :key="`${index}_${lendingIndex}_${lendingItem.id}`"
            class="row-item"
          >
            <b-td v-if="showNumber" />
            <b-td style="padding-left: 30px">
              {{ lendingItem.product.name }}
            </b-td>
            <b-td class="text-center">
              {{ lendingItem.amount }}
            </b-td>
            <b-td :class="priceTextAlign">
              {{ 0 | currency }}
            </b-td>
            <b-td />
            <b-td v-if="!itemReadOnly" />
          </b-tr>
        </template>

        <!-- row de footer (totalizadores) -->
      </template>

      <template v-if="!isItemsEmpty && showTableFooter">
        <b-tr class="row-footer text-light">
          <b-td v-if="showNumber" />
          <b-td class="text-right">
            Total:
          </b-td>
          <b-td class="text-center">
            {{ resumeData.totalQuantity || 0 }}
          </b-td>
          <b-td class="text-right">
            Total:
          </b-td>
          <b-td :class="priceTextAlign">
            {{ resumeData.total | currency }}
          </b-td>
          <b-td v-if="!itemReadOnly" />
        </b-tr>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import { BTableSimple, BTh, BTd, BTr, BThead, BTbody, BImg } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { formulateHelper, keyboardCode } from '@/mixins'
import logoImg from '@/assets/images/logo/echope_small.png'

const getInitialForm = () => ({
  amount: 1,
})

export default {
  components: {
    BTableSimple,
    BTh,
    BTd,
    BTr,
    BThead,
    BTbody,
    BImg,
    EButton,
  },

  mixins: [formulateHelper, keyboardCode],

  props: {
    minHeight: {
      type: Number,
      default: 0,
    },

    tableItems: {
      type: Array,
      default: () => [],
    },

    itemReadOnly: {
      type: Boolean,
      default: false,
    },

    pricesToRight: {
      type: Boolean,
      default: false,
    },

    showNumber: {
      type: Boolean,
      default: true,
    },

    showImg: {
      type: Boolean,
      default: false,
    },

    showTableFooter: {
      type: Boolean,
      default: false,
    },

    /**
     * {
     *    totalQuantity: number,
     *    total: number
     * }
     */
    resumeData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      indexUpdate: null,
      formUpdate: getInitialForm(),
      delegateUserData: null,
      logoImg,
    }
  },

  computed: {
    isItemsEmpty() {
      return this.tableItems.length === 0
    },

    priceTextAlign() {
      return `${this.pricesToRight ? 'text-right' : 'text-left'}`
    },

    fields() {
      return [
        {
          label: this.$t('#'),
          key: 'index',
          class: 'text-center',
          thStyle: { width: '50px' },
          hide: !this.showNumber
        },
        {
          label: this.$t('Nome do Produto'),
          key: 'product',
          class: 'text-left',
        },
        {
          label: this.$t('Quant.'),
          key: 'amount',
          class: 'text-center',
          thStyle: { width: '70px' },
        },
        {
          label: this.$t('Preço un.'),
          key: 'price',
          class: 'text-left',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Total'),
          key: 'subtotal',
          class: this.priceTextAlign,
          thStyle: { width: '110px' },
        },
        {
          label: this.$t('Ações'),
          key: 'actions',
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: this.itemReadOnly,
        },
      ].filter(a => !a.hide)
    },
  },

  methods: {
    getPositionItem(index) {
      const calcKitItensLenght = i =>
        (i.product.kitItems?.length || 0) +
        (i.product.kitItems?.filter(ki => ki.product?.lendingItemAssociated?.length > 0)
          ?.length || 0)

      const traveledItems = this.tableItems.slice(0, index + 1)
      const traveledItemsKit = traveledItems.filter(i => i.product.kit)
      const totalTraveledItems =
        traveledItemsKit.reduce((total, i) => total + calcKitItensLenght(i), 0) +
        traveledItems.length -
        traveledItemsKit.length

      const itemsKit = this.tableItems.filter(i => i.product.kit)
      const totalItems =
        itemsKit.reduce((total, i) => total + calcKitItensLenght(i), 0) +
        this.tableItems.length -
        itemsKit.length

      return totalItems - totalTraveledItems + 1
    },

    getKitItems(kitItems) {
      if (!kitItems) return []

      const result = kitItems.flatMap(ki => {
        if (ki.product?.lendingItemAssociated?.length > 0) {
          return [ki, ki.product?.lendingItemAssociated[0]]
        }

        return [ki]
      })

      return result
    },

    async showEditAmountCell(index, item) {
      const showAction = () => {
        this.addKeyEvent(this.listShortcut)
        this.formUpdate = { amount: item.amount }
        this.productUpdate = item
        this.indexUpdate = index
        setTimeout(() => this.focusInput(`#paybox-item-list-amount-${index}`), 100)
      }

      this.$emit('show-edit-quantity', showAction, item)
    },
    cleanUpdate(item) {
      this.indexUpdate = null
      this.formUpdate = getInitialForm()

      this.$emit('clean-row-update', item)
    },

    updateItem(item) {
      this.$emit('update-item', item)
    },

    async removeItem(item) {
      const confirm = await this.confirm({
        message: `Remover o produto: ${item.name}?`,
        focusCancel: true,
      })
      if (!confirm) return

      this.$emit('remove-item')
    },
  },
}
</script>

<style lang="scss" scoped>
.sale-items-table {
  width: 100%;
}

.sale-items-thead {
  height: 50px;

  .sale-items-th {
    background-color: var(--theme-color) !important;
    color: var(--white) !important;
  }
}

.sale-items-canceled-price {
  text-decoration: line-through;
  color: var(--danger);
}

.sale-items-tbody {
  &>tr>td {
    padding: 5px 10px;
    font-size: 1rem;
  }

  &>tr.row-item:nth-child(2n) {
    background-color: rgba(var(--theme-color-rgb), 0.15);
  }

  .row-footer {
    background-color: var(--theme-color);

  }

  .edit-cell {
    cursor: pointer;
  }
}

.footer-value-info {
  p {
    line-height: 25px;
  }
}
</style>
